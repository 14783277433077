import gql from "graphql-tag";
import {Query} from "@deity/falcon-ecommerce-uikit";

export const GET_CMS_BLOCK = gql`
  query CMSBlock($identifier: String!) {
    cmsBlock(identifier: $identifier) {
        title
        content
        active
    }
  }
`;

export class CMSBlockQuery extends Query {
  static defaultProps = {
    query: GET_CMS_BLOCK,
  };
}
