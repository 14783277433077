import gql from 'graphql-tag';
import {Query} from "@deity/falcon-ecommerce-uikit";

export const GET_CMSPAGE = gql`
  query CmsPage($id: String!) {
    cmsPage(id: $id) {
      metaTitle
      metaDescription
      content
      template
    }
  }
`;

export class CmsPageQuery extends Query {
    static defaultProps = {
        query: GET_CMSPAGE
    };
}
