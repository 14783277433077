import React from 'react';
import {Switch, Route} from 'react-router-dom';
import Helmet from 'react-helmet';
import {
  Loader
} from '@deity/falcon-ecommerce-uikit';
import {
  HelpLayout,
  HelpSidebar,
  HelpSection,
  FooterHelpPage,
} from "benlux-ecommerce-ui";
import {CmsPageQuery} from "../../queries/CmsPageQuery";
import Query from "@deity/falcon-client/src/components/Query";
import {GET_URL} from "@deity/falcon-client/src/graphql/url.gql";
import {CMSBlockQuery} from "../../queries/CMSBlockQuery";


const HelpSectionLayout = ({match, contents}) => {
  const {slug} = match.params;
  const section = contents.sections.find(s => s.slug === slug);

  return (
    <HelpSidebar sections={contents.sections}>
      <Helmet>
        <title>{`${section.title} - Aide`}</title>
        <meta name="description" content=""/>
        <meta name="page-loaded" content="true"/>
      </Helmet>
      <HelpSection section={section}/>
      <CMSBlockQuery variables={{identifier: 'footer-help-page'}}>
        {({cmsBlock, loading}) => {
          if (!cmsBlock && loading) {
            return <Loader/>;
          }
          const content = JSON.parse(cmsBlock.content);
          return (
            <FooterHelpPage content={content}/>
          );
        }}
      </CMSBlockQuery>
    </HelpSidebar>
  )
}


const Help = () => {
  return (
    <Query query={GET_URL} variables={{path: 'aide'}}>
      {({data}) => {
        const {id} = data.url;
        return (
          <CmsPageQuery variables={{id}}>
            {({cmsPage, loading}) => {
              if (!cmsPage && loading) {
                return <Loader/>;
              }

              const contents = JSON.parse(cmsPage.content);

              return (
                <CMSBlockQuery variables={{identifier: 'footer-help-page'}}>
                  {({cmsBlock, loading}) => {
                    if (!cmsBlock && loading) {
                      return <Loader/>;
                    }
                    const contentFooter = JSON.parse(cmsBlock.content);
                    return (
                      <Switch>
                        <Route exact path="/aide" render={(props) => (
                          <>
                            <Helmet>
                              <title>Aide</title>
                              <meta name="description" content=""/>
                              <meta name="page-loaded" content="true"/>
                            </Helmet>
                            <HelpLayout {...props} content={contents} contentFooter={contentFooter}/>
                          </>
                        )}/>
                        <Route path="/aide/:slug" render={(props) => (
                          <HelpSectionLayout {...props} contents={contents}/>
                        )}/>
                      </Switch>
                    );
                  }}
                </CMSBlockQuery>
              )
            }}
          </CmsPageQuery>
        )
      }}
    </Query>
  );
}

export default Help;
